type ContactProps = {
  height: number,
}

export const Contact = ({height}:ContactProps) => {
  return(
    <>
      <p style={{fontSize: (height / 4).toString() + "px"}}>contact</p>
    </>
  );
};