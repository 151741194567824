type HomeProps = {
  height: number,
}

export const Home = ({height}:HomeProps) => {
  return(
    <>
      <p style={{fontSize: (height / 4).toString() + "px"}}>mrbl</p>
    </>
  );
};