type AppsProps = {
  height: number,
}

export const Apps = ({height}:AppsProps) => {
  return(
    <>
      <p style={{fontSize: (height / 4).toString() + "px"}}>apps</p>
    </>
  );
};