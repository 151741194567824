import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Apps } from './pages/Apps';
import { Contact } from './pages/Contact';
import { PageNotFound } from './pages/PageNotFound';
import { Navigation } from './components/Navigation';

import './css/App.css';

export const App = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  console.log(height.toString() + "px");
  return (
    <BrowserRouter>
    <div className="App">
        <Routes>
          <Route path='/' element={<Home height={height}/>}></Route>
          <Route path='/apps' element={<Apps height={height}/>}></Route>
          <Route path='/about' element={<About height={height}/>}></Route>
          <Route path='/contact' element={<Contact height={height}/>}></Route>
          <Route path="*" element={<PageNotFound height={height}/>}></Route>
        </Routes>
    </div>
    <Navigation />
    </BrowserRouter>
  );
};