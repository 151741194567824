import { useState} from "react";

import { Hamburger } from "./Hamburger";
import { Link } from "react-router-dom";

import '../css/Navigation.css';
import '../css/Hamburger.css';

export const Navigation = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  }

  return(
    <div>
      <div className="navigation">
        <div className="hamburger" onClick={toggleHamburger}>
          <Hamburger />
        </div>
        { hamburgerOpen &&
          <ul>
            <li><Link to='/'>home</Link></li>
            <li><Link to='/about'>about</Link></li>
            <li><Link to='/apps'>apps</Link></li>
            <li><Link to='/contact'>contact</Link></li>
          </ul>
        }
      </div>
    </div>
  );
};