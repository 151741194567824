type PageNotFoundProps = {
  height: number,
}

export const PageNotFound = ({height}:PageNotFoundProps) => {
  return(
    <>
      <p style={{fontSize: (height / 4).toString() + "px"}}>404</p>
    </>
  );
};