type AboutProps = {
  height: number,
}

export const About = ({height}:AboutProps) => {
  return(
    <>
      <p style={{fontSize: (height / 4).toString() + "px"}}>about</p>
    </>
  );
};